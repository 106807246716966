import { toLower } from "lodash";
import React, { useState } from "react";
import { Button, Checkbox, Input, Modal, Notification, Panel, Placeholder, Table } from "rsuite";
import ModalBody from "rsuite/lib/Modal/ModalBody";
import ModalTitle from "rsuite/lib/Modal/ModalTitle";
import TablePagination from "rsuite/lib/Table/TablePagination";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";
import { PsmContactFkPersonContactIdEvents } from "../../../events/EventHolder";

const { Column, HeaderCell, Cell } = Table;

export class AllocatedPackage extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        isLoading: false,
        value: null,
        isModalSelect: false,
        data: [],
        dataChecked: [],
        checkedKeys: [],
        dataFormPsms: [],
        oldData: [],
        textFilter: ''
    }


    componentDidMount = async () => {

    }

    onChange = (item: any, valuesOmie: any) => {
        this.props.onChange()
    };


    getValuesPsms = async () => {
        for (var i in this.props.values) {
            var dados = this.props.values[i];
            if (i == 'box_inclusions') {
                this.setState({
                    checkedKeys: dados
                })
            }
        }
        this.setState({ loading: true })
        await axios
            .get("/api/v1/packing/list", {
                params: {
                    guid: this.props.guid
                }
            })
            .then((res) => {
                if (res.data.status == 200) {
                    this.setState({ data: res.data.response, isModalSelect: true })
                } else {
                    Notification.error({
                        title: "error!",
                        description: 'Não foi possivel listar Psms',
                        duration: 6500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })

    }

    isCheckedItem = (value: any = null, checked: any = null) => {
        const checkedKeys = this.state.checkedKeys;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    isSelectPsms = async () => {
        await axios
            .post("/api/v1/packing/validate-add", { data: this.state.checkedKeys, guid: this.props.guid })
            .then((res) => {
                if (res.data.validate == false) {
                    Notification.error({
                        title: res.data.message,
                        duration: 4000,
                    })
                    return
                }
                this.setState({
                    // isModalSelect: false,
                    checkedKeys: []
                })
                this.props.onChange(res.data.data);

            }).catch((e) => {
                Notification.error({
                    description: e,
                    duration: 4000,
                })
            })
    }

    renderModalAllocated = () => {
        const rowSize = this.props.compact ? 25 : undefined;

        return (<>
            <CustomModal
                show={this.state.isModalSelect}
                widthModal={"rs-modal-full"}
                tableOmie={false}
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        isModalSelect: false,
                    })
                }}
                title={"Selecione quais Psm's deseja atribuir a essa embalagem"}
                showInviteButton={true}
                inviteButton={() => this.isSelectPsms()}
                showConfirm={false}
                body={<>
                    <Panel bordered bodyFill style={{ padding: 8, width: '100%', minHeight: '999px' }}>
                        <Table
                            // autoHeight
                            wordWrap
                            loading={this.props.loading}
                            headerHeight={rowSize}
                            rowHeight={rowSize}
                            autoHeight
                            style={{ width: '100%' }}
                            data={this.state.data}
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                        >
                            <Column width={90} align='center' fixed>
                                <HeaderCell>Selecionar</HeaderCell>
                                <Cell className="link-group" align='center' style={{ alignItems: 'center' }}>
                                    {(rowData: any) => {
                                        return (
                                            <div style={{ alignContent: 'center' }}>
                                                <Checkbox
                                                    value={rowData}
                                                    inline
                                                    onChange={this.isCheckedItem}
                                                    checked={this.state.checkedKeys.some(item => item === rowData)}
                                                />
                                            </div>
                                        );
                                    }}
                                </Cell>
                            </Column>
                            <Column width={220} resizable>
                                <HeaderCell>Unidade</HeaderCell>
                                <Cell dataKey="box_inclusion_company" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>N° NF</HeaderCell>
                                <Cell dataKey="box_inclusion_num_nf" />
                            </Column>
                            <Column width={70} resizable>
                                <HeaderCell>BU</HeaderCell>
                                <Cell dataKey="box_inclusion_business_unity" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>psm</HeaderCell>
                                <Cell dataKey="box_inclusion_os_number" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Cliente</HeaderCell>
                                <Cell dataKey="box_inclusion_person" />
                            </Column>
                            <Column width={150} resizable>
                                <HeaderCell>CNPJ/CPF</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        const cnpjCpf = (value: any) => {
                                            if (value.length > 11) {
                                                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                                                return value
                                            }
                                            if (value.length <= 11) {
                                                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$5")
                                                return value
                                            }
                                        }
                                        return (<>
                                            {cnpjCpf(rowData.box_inclusion_cnpj)}
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Cidade</HeaderCell>
                                <Cell dataKey="box_inclusion_city" />
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Uf</HeaderCell>
                                <Cell dataKey="box_inclusion_state" />
                            </Column>
                            <Column width={80} resizable>
                                <HeaderCell>Frete</HeaderCell>
                                <Cell dataKey="box_inclusion_shipment_type" />
                            </Column>
                            <Column width={180} resizable>
                                <HeaderCell>Transportadora</HeaderCell>
                                <Cell dataKey="box_inclusion_shipping_company" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Situação</HeaderCell>
                                <Cell dataKey="box_inclusion_status" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Situação Comercial</HeaderCell>
                                <Cell dataKey="box_inclusion_comercial_status" />
                            </Column>
                            <Column width={70} resizable>
                                <HeaderCell>Data de entrega final</HeaderCell>
                                <Cell dataKey="psm_os_number" />
                            </Column>
                        </Table>
                        {/* <TablePagination
                        lengthMenu={[
                            {
                                value: 10,
                                label: 10
                            },
                            {
                                value: 20,
                                label: 20
                            }
                        ]}
                    /> */}
                    </Panel>
                </>}
            />
        </>)
    }

    changeFilter = (stringFilter: any) => {
        let { data, oldData } = this.state;
        let filtered: any = [];
    
        // Garantir que oldData está sempre inicializado corretamente
        if (!oldData || oldData.length === 0) {
            oldData = data;
        }
    
        if (stringFilter.length > 0) {
            console.log("stringFilter ::>", stringFilter);
            for (var i in oldData) { // Sempre usa oldData como base para filtragem
                let item = oldData[i];
                let contain = false;
    
                for (var key in item) {
                    let res: any = item[key];
                    if (toLower(res).includes(toLower(stringFilter))) {
                        contain = true;
                        break; // Otimiza a busca, para de verificar após encontrar
                    }
                }
    
                if (contain) {
                    filtered.push(item);
                }
            }
    
            // Atualiza o estado com os resultados filtrados
            this.setState({
                data: filtered,
                oldData: oldData, // Garante que oldData permanece intacto
            });
        } else {
            // Quando a string de filtro está vazia, retorna aos dados originais
            this.setState({
                data: oldData,
                oldData: oldData, // Garante que oldData permanece intacto
            });
        }
    };
    

    render() {
        return (<>
            {/* {this.renderModalAllocated()} */}
            {/* <Modal size="lg" show={this.state.isModalSelect} onHide={() => this.setState({ isModalSelect: false })}>
                <Modal.Header>
                    <h4>Selecione quais Psm's deseja atribuir a essa embalagem</h4>
                </Modal.Header>
                <ModalBody> */}
            <Button color="violet" size={"lg"} loading={this.state.loading} style={{ marginTop: 15, width: "200px" }} onClick={() => this.getValuesPsms()}>
                <i className="fas fa-folder-tree"></i> Selecionar Psms
            </Button>
            {/* {JSON.stringify(this.state.checkedKeys)}

            {JSON.stringify(this.state.dataChecked)} */}

            {this.state.isModalSelect && (
                <div style={{ height: "auto" }} >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input onChange={(value) => this.setState({ textFilter: value })} style={{ margin: 12 }} placeholder="Filtrar..." ></Input>
                        <button type="button" onClick={() => this.changeFilter(this.state.textFilter.trim())}>Pesquisar</button>
                    </div>
                    <Panel bordered bodyFill style={{ padding: 8 }} >
                        <Table
                            fillHeight
                            // autoHeight
                            // wordWrap
                            loading={this.props.loading}
                            height={600}

                            data={this.state.data}
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                        >
                            <Column width={90} align='center' fixed>
                                <HeaderCell>Selecionar</HeaderCell>
                                <Cell className="link-group" align='center' style={{ alignItems: 'center' }}>
                                    {(rowData: any) => {
                                        return (
                                            <div style={{ alignContent: 'center' }}>
                                                <Checkbox
                                                    value={rowData}
                                                    inline
                                                    onChange={this.isCheckedItem}
                                                    checked={this.state.checkedKeys.some(item => item === rowData)}
                                                />
                                            </div>
                                        );
                                    }}
                                </Cell>
                            </Column>
                            <Column width={220} resizable>
                                <HeaderCell>Unidade</HeaderCell>
                                <Cell dataKey="box_inclusion_company" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>N° NF</HeaderCell>
                                <Cell dataKey="box_inclusion_num_nf" />
                            </Column>
                            <Column width={70} resizable>
                                <HeaderCell>BU</HeaderCell>
                                <Cell dataKey="box_inclusion_business_unity" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>psm</HeaderCell>
                                <Cell dataKey="box_inclusion_os_number" />
                            </Column>
                            <Column width={250} resizable>
                                <HeaderCell>Cliente</HeaderCell>
                                <Cell dataKey="box_inclusion_person" />
                            </Column>
                            <Column width={150} resizable>
                                <HeaderCell>CNPJ/CPF</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        const cnpjCpf = (value: any) => {
                                            if (value.length > 11) {
                                                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                                                return value
                                            }
                                            if (value.length <= 11) {
                                                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$5")
                                                return value
                                            }
                                        }
                                        return (<>
                                            {cnpjCpf(rowData.box_inclusion_cnpj)}
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Cidade</HeaderCell>
                                <Cell dataKey="box_inclusion_city" />
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Uf</HeaderCell>
                                <Cell dataKey="box_inclusion_state" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Frete</HeaderCell>
                                <Cell dataKey="box_inclusion_shipment_type" />
                            </Column>
                            <Column width={220} resizable>
                                <HeaderCell>Transportadora</HeaderCell>
                                <Cell dataKey="box_inclusion_shipping_company" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Situação</HeaderCell>
                                <Cell dataKey="box_inclusion_status" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Situação Comercial</HeaderCell>
                                <Cell dataKey="box_inclusion_comercial_status" />
                            </Column>
                            <Column width={70} resizable>
                                <HeaderCell>Data de entrega final</HeaderCell>
                                <Cell dataKey="psm_os_number" />
                            </Column>
                        </Table>
                        <Button disabled={this.props.loading} onClick={() => this.isSelectPsms()} color={"green"}>
                            Concluir
                        </Button>
                    </Panel>
                </div>
            )}

            {/* </ModalBody>
            </Modal> */}


        </>)
    }
}