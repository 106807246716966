import axios from "../../utilities/axios";
import { Event } from "../Event";

export class ProductBoxAllocatedPackageEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        let isTypePsm = value[0].material_requisition_number_psm_os_number.substring(0,2);
        isTypePsm = isTypePsm.substring(1);
        const isCrud = isTypePsm == 'L' ? 'commercials' : 'dibi';

        this.setState('loadings',[
            'product_box_fk_person_id',
            'product_box_cnpj_cpf',
            'product_box_person_city',
            'product_box_person_state',
            'product_box_seller_internal',
            'product_box_seller_external',
            'product_box_shippment_fk_person_id',
            'product_box_fk_type_frete_id',
            'product_box_redispatch_fk_person_id',
            'product_box_business_unity',
            'product_box_inclusions',
            'product_box_fk_company_id',
            'product_box_romanian'
        ])
        const newObject = [];
        for(const i in value){
            newObject.push({
                product_box_inclusion_intern_reference: value[i][`material_requisition_logistic_intern_reference`],
                product_box_inclusion_item_description: value[i][`material_requisition_logistic_item_description`],
                product_box_inclusion_quantity_met: value[i][`material_approved_grid_quantity_met`],
                product_box_inclusion_number_psm_os_number: value[i][`material_requisition_number_psm_os_number`],
                product_box_inclusion_os_number: value[i][`material_requisition_os_number`],
                product_box_inclusion_id_psm: value[i][`material_requisition_fk_psm_id`],
                product_box_inclusion_request_user: value[i][`material_requisition_request_user`],
                product_box_inclusion_material_requisition_id: value[i][`material_requisition_id`],
                product_box_inclusion_approved_grid_id: value[i][`material_approved_grid_id`],
                product_box_inclusion_table_product_id: value[i][`material_requisition_logistic_table_product_id`],
                product_box_inclusion_serial_number: value[i][`material_approved_grid_series`]
            })
        }
        this.setFieldValue('product_box_inclusions', newObject)
        
        
        
        axios.get(`/api/v1/${isCrud}/` + value[0]['material_requisition_fk_psm_id'])
        .then(async(res) => {
            const itens = res.data.item;
            const typePerson = itens.person !== null ? 'client' : 'bills'
            // console.log("typePerson :>", typePerson)
            // console.log("itens :>", itens)
            this.setFieldValue('product_box_fk_person_id',itens.psm_clients_fk_person_id);
            // this.setFieldValue('product_box_cnpj_cpf', itens[typePerson].person_cnpj_cpf);   
            this.setFieldValue('product_box_seller_internal',itens.psm_internal);
            this.setFieldValue('product_box_seller_external',itens.psm_external);
            if(itens.psm_resend_fk_person_id !== null){
                this.setFieldValue('product_box_redispatch_fk_person_id',itens.psm_resend_fk_person_id);
            }
            this.setFieldValue('product_box_fk_company_id',itens.psm_fk_company_id)
            if(itens.psm_carriers_fk_person_id !== null){
                this.setFieldValue('product_box_shippment_fk_person_id',itens.psm_carriers_fk_person_id);
                this.setState("loadings", [
                    "product_box_cnpj_transport", "product_box_city_transport"
                ]);

                axios
                    .get(`/api/v1/psm/getcarrierinfos/${itens.psm_carriers_fk_person_id}`)
                    .then((res) => {
                        const infos = res.data.infos
                        this.setFieldValue("product_box_cnpj_transport", infos.cnpj)
                        this.setFieldValue("product_box_city_transport", infos.city)
                    })

                setTimeout(() => {
                    this.setState("loadings", []);
                }, 2000)
            }
            if(itens.psm_shipment_fk_type_frete_id !== null){
                this.setFieldValue('product_box_fk_type_frete_id',itens.psm_shipment_fk_type_frete_id);
            }
            if(itens[typePerson].person_fk_city_id)
            await axios.get(`/api/v1/cities/` + itens[typePerson].person_fk_city_id).then((resCity) => {
                const itensCity = resCity.data.item;
                this.setFieldValue('product_box_person_city', itensCity.city_name)
            }).catch((e) => {
                console.log(e)
            })
            await axios.get('/api/v1/states/' + itens[typePerson].person_fk_state_id).then((resState) => {
                this.setFieldValue('product_box_person_state', resState.data.item.state_abbreviation)
            }).catch((e) => {
                console.log(e)
            })
            await axios
            .get(`/api/v1/product-box/next-code/${itens.psm_fk_company_id}`)
            .then((res) => {
                console.log({res});
                if(res.data.status == 200){
                    this.setFieldValue("product_box_romanian", res.data.code);
                    this.setFieldValue('product_box_internal_code',res.data.sequencial);
                }
            }).catch((e) => {console.log(e)})
        }).finally(() => {
            this.setState('loadings',[])
        })
    }
}